import _ from 'lodash';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {ElicitationContext} from 'src/ElicitationContext/ElicitationContext';
import IExactSwingRatio from 'src/Interface/IExactSwingRatio';
import ISliderParameters from './ISliderParameters';
import IThresholdElicitationContext from './IThresholdElicitationContext';
import {
  calculateInputValuesByCriterion,
  calculateReferenceChangeRatio,
  getInitialValidity,
  getReferenceSliderParameters
} from './ThresholdElicitationUtil';
import {TThresholdChangeDirection} from './TThresholdChangeDirection';

export const ThresholdElicitationContext =
  createContext<IThresholdElicitationContext>(
    {} as IThresholdElicitationContext
  );

export function ThresholdElicitationContextProviderComponent({
  children
}: {
  children: any;
}) {
  const {criteria, pvfs, getCriterion, stepSizeByCriterion} =
    useContext(ElicitationContext);

  const [direction, setDirection] = useState<TThresholdChangeDirection>();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [
    equivalentChangeForReferenceCriterion,
    setEquivalentChangeForReferenceCriterion
  ] = useState<number>();
  const [mostImportantCriterionId, setMostImportantCriterionId] =
    useState<string>();
  const [preferences, setPreferences] = useState<
    Record<string, IExactSwingRatio>
  >({});
  const [sliderParameters, setSliderParameters] = useState<ISliderParameters>();
  const [inputValuesByCriterion, setInputValuesByCriterion] =
    useState<Record<string, number>>();

  const [valueValidityByCriterionId, setValueValidityByCriterionId] = useState<
    Record<string, boolean>
  >(getInitialValidity(criteria));

  useEffect(() => {
    if (mostImportantCriterionId) {
      const range = pvfs[mostImportantCriterionId].range;
      const referenceCriterion = getCriterion(mostImportantCriterionId);
      const predefinedStepSize = stepSizeByCriterion[referenceCriterion.id];
      const [stepSize, maxValue] = getReferenceSliderParameters(
        range,
        predefinedStepSize
      );
      setEquivalentChangeForReferenceCriterion(stepSize);
      setSliderParameters({
        minValue: stepSize,
        step: stepSize,
        maxValue: maxValue
      });
    }
  }, [getCriterion, mostImportantCriterionId, pvfs, stepSizeByCriterion]);

  useEffect(() => {
    if (mostImportantCriterionId && equivalentChangeForReferenceCriterion) {
      const otherCriteria = _.reject(criteria, [
        'id',
        mostImportantCriterionId
      ]);
      const referenceChangeRatio = calculateReferenceChangeRatio(
        equivalentChangeForReferenceCriterion,
        pvfs[mostImportantCriterionId].range
      );

      const newInputValues = calculateInputValuesByCriterion(
        otherCriteria,
        referenceChangeRatio,
        pvfs,
        stepSizeByCriterion
      );

      setInputValuesByCriterion(newInputValues);
    }
  }, [
    criteria,
    equivalentChangeForReferenceCriterion,
    mostImportantCriterionId,
    pvfs,
    stepSizeByCriterion
  ]);

  function setCriterionInputValue(criterionId: string, value: number): void {
    const newInputValues = {
      ..._.cloneDeep(inputValuesByCriterion),
      [criterionId]: value
    };
    setInputValuesByCriterion(newInputValues);
  }

  function getInputValueForCriterion(criterionId: string): number {
    return inputValuesByCriterion[criterionId];
  }

  function setIsValidValue(criterionId: string, validity: boolean) {
    const newValidity: Record<string, boolean> = {
      ...valueValidityByCriterionId,
      [criterionId]: validity
    };
    setValueValidityByCriterionId(newValidity);
    setIsSaveButtonDisabled(hasInvalidValue(newValidity));
  }

  function hasInvalidValue(validityById: Record<string, boolean>) {
    return _.some(validityById, (validity) => !validity);
  }

  function setSliderUpperBound(newUpper: number) {
    setSliderParameters({...sliderParameters, maxValue: newUpper});
  }

  return (
    <ThresholdElicitationContext.Provider
      value={{
        direction,
        mostImportantCriterionId,
        preferences,
        equivalentChangeForReferenceCriterion,
        sliderParameters,
        inputValuesByCriterion,
        isSaveButtonDisabled,
        setDirection,
        setIsSaveButtonDisabled,
        setIsValidValue,
        setMostImportantCriterionId,
        setPreferences,
        setEquivalentChangeForReferenceCriterion,
        setCriterionInputValue,
        setSliderUpperBound,
        getInputValueForCriterion
      }}
    >
      {children}
    </ThresholdElicitationContext.Provider>
  );
}
