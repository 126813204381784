import Grid from '@material-ui/core/Grid';
import React, {useContext} from 'react';
import {ThresholdElicitationContext} from '../ThresholdElicitationContext';
import ThresholdElicitationDirectionChoice from '../ThresholdElicitationDirectionChoice/ThresholdElicitationDirectionChoice';
import ReferenceCriterionChoice from './ReferenceCriterionChoice/ReferenceCriterionChoice';
import ReferenceSlider from './ReferenceSlider/ReferenceSlider';

export default function ThresholdElicitationStep1(): JSX.Element {
  const {mostImportantCriterionId, sliderParameters, direction} = useContext(
    ThresholdElicitationContext
  );

  return (
    <Grid container spacing={2}>
      <ReferenceCriterionChoice />
      {mostImportantCriterionId && sliderParameters ? (
        <>
          <ThresholdElicitationDirectionChoice />
          {direction ? <ReferenceSlider /> : <></>}
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}
