import {Box, Grid} from '@material-ui/core';
import {HelpContextProviderComponent, IHelpInfo} from 'help-popup';
import React from 'react';
import {ChoiceBasedMatchingContextProviderComponent} from 'src/ChoiceBasedMatchingElicitation/ChoiceBasedMatchingContext';
import ChoiceBasedMatchingElicitation from 'src/ChoiceBasedMatchingElicitation/ChoiceBasedMatchingElicitation';
import {ElicitationContextProviderComponent} from 'src/ElicitationContext/ElicitationContext';
import ICriterion from 'src/Interface/ICriterion';
import IExactSwingRatio from 'src/Interface/IExactSwingRatio';
import IUpperRatioConstraint from 'src/Interface/IUpperRatioConstraint';
import {TPvf} from 'src/Interface/TPvf';
import ThresholdElicitation from 'src/ThresholdElicitation/ThresholdElicitation';
import {ThresholdElicitationContextProviderComponent} from 'src/ThresholdElicitation/ThresholdElicitationContext';
import {TElicitationMethod} from 'src/Types/TElicitationMethod';

export default function PreferenceElicitationPrivate({
  criteria,
  elicitationMethod,
  manualHost,
  manualLexicon,
  manualPath,
  previousCallback,
  pvfs,
  showPercentages,
  showCbmPieChart,
  stepSizeByCriterion,
  cancelCallback,
  saveCallback,
  setErrorMessage
}: {
  criteria: ICriterion[];
  elicitationMethod: TElicitationMethod;
  manualHost: string;
  manualLexicon: Record<string, IHelpInfo>;
  manualPath: string;
  previousCallback?: () => void;
  pvfs: Record<string, TPvf>;
  showPercentages: boolean;
  showCbmPieChart?: boolean;
  stepSizeByCriterion: Record<string, number>;
  cancelCallback?: () => void;
  saveCallback: (
    preferences: IExactSwingRatio[] | IUpperRatioConstraint[],
    thresholdValuesByCriterion?: Record<string, number>
  ) => Promise<any>;
  setErrorMessage: (error: string) => void;
}): JSX.Element {
  function renderElicitation(
    elicitationMethod: TElicitationMethod
  ): JSX.Element {
    switch (elicitationMethod) {
      case 'choice':
        return (
          <ChoiceBasedMatchingContextProviderComponent>
            <ChoiceBasedMatchingElicitation />
          </ChoiceBasedMatchingContextProviderComponent>
        );
      case 'threshold':
        return (
          <ThresholdElicitationContextProviderComponent>
            <ThresholdElicitation />
          </ThresholdElicitationContextProviderComponent>
        );
    }
  }

  return (
    <ElicitationContextProviderComponent
      previousCallback={previousCallback}
      criteria={criteria}
      elicitationMethod={elicitationMethod}
      showPercentages={showPercentages}
      showCbmPieChart={showCbmPieChart}
      pvfs={pvfs}
      cancelCallback={cancelCallback}
      saveCallback={saveCallback}
      setErrorMessage={setErrorMessage}
      stepSizeByCriterion={stepSizeByCriterion}
    >
      <HelpContextProviderComponent
        lexicon={manualLexicon}
        host={manualHost}
        path={manualPath}
      >
        <Grid container justify="center" component={Box} mt={2}>
          {renderElicitation(elicitationMethod)}
        </Grid>
      </HelpContextProviderComponent>
    </ElicitationContextProviderComponent>
  );
}
