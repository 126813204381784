import _ from 'lodash';
import React, {createContext, useCallback, useState} from 'react';
import ICriterionInfo from 'src/ChoiceBasedMatchingElicitation/ChoiceBasedMatchingQuestionAndAnswer/ChoiceBasedMatchingQuestion/ICriterionInfo ';
import ICriterion from 'src/Interface/ICriterion';
import IExactSwingRatio from 'src/Interface/IExactSwingRatio';
import IUpperRatioConstraint from 'src/Interface/IUpperRatioConstraint';
import {TPvf} from 'src/Interface/TPvf';
import {TElicitationMethod} from 'src/Types/TElicitationMethod';
import {canBePercentage, getUnitLabel} from 'src/Util/util';
import IElicitationContext from './IElicitationContext';

export const ElicitationContext = createContext<IElicitationContext>(
  {} as IElicitationContext
);

export function ElicitationContextProviderComponent({
  elicitationMethod,
  criteria,
  showPercentages,
  showCbmPieChart,
  previousCallback,
  pvfs,
  stepSizeByCriterion,
  cancelCallback,
  saveCallback,
  setErrorMessage,
  children
}: {
  elicitationMethod: TElicitationMethod;
  criteria: ICriterion[];
  showPercentages: boolean;
  showCbmPieChart: boolean;
  previousCallback?: () => void;
  pvfs: Record<string, TPvf>;
  stepSizeByCriterion: Record<string, number>;
  cancelCallback: () => void;
  saveCallback: (
    preferences: IExactSwingRatio[] | IUpperRatioConstraint[],
    thresholdValuesByCriterion?: Record<string, number>
  ) => Promise<any>;
  setErrorMessage: (error: string) => void;
  children: any;
}): JSX.Element {
  const [currentStep, setCurrentStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const getCriterion = useCallback(
    (id: string): ICriterion => {
      return _.find(criteria, ['id', id]);
    },
    [criteria]
  );

  const getCriterionInfo = useCallback(
    (criterionId: string): ICriterionInfo => {
      const criterion: ICriterion = _.find(criteria, ['id', criterionId]);
      const unit = criterion.dataSources[0].unitOfMeasurement;
      const usePercentage = criterion
        ? showPercentages && canBePercentage(unit.type)
        : false;
      return {
        title: criterion ? criterion.title : '',
        description:
          criterion && criterion.description ? criterion.description : '',
        usePercentage: usePercentage,
        unitOfMeasurement: getUnitLabel(unit, showPercentages)
      };
    },
    [criteria, showPercentages]
  );

  return (
    <ElicitationContext.Provider
      value={{
        currentStep,
        isNextDisabled,
        elicitationMethod,
        showPercentages,
        showCbmPieChart,
        pvfs,
        criteria,
        stepSizeByCriterion,
        getCriterion,
        getCriterionInfo,
        previousCallback,
        setCurrentStep,
        setIsNextDisabled,
        cancelCallback,
        saveCallback,
        setErrorMessage
      }}
    >
      {children}
    </ElicitationContext.Provider>
  );
}
