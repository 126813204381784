(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@material-ui/core/Box"), require("@material-ui/core/Grid"), require("@material-ui/core/Popover"), require("@material-ui/core/Typography"), require("@material-ui/icons/Help"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@material-ui/core/Box", "@material-ui/core/Grid", "@material-ui/core/Popover", "@material-ui/core/Typography", "@material-ui/icons/Help", "react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@material-ui/core/Box"), require("@material-ui/core/Grid"), require("@material-ui/core/Popover"), require("@material-ui/core/Typography"), require("@material-ui/icons/Help"), require("react")) : factory(root["@material-ui/core/Box"], root["@material-ui/core/Grid"], root["@material-ui/core/Popover"], root["@material-ui/core/Typography"], root["@material-ui/icons/Help"], root["react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__658__, __WEBPACK_EXTERNAL_MODULE__720__, __WEBPACK_EXTERNAL_MODULE__280__, __WEBPACK_EXTERNAL_MODULE__740__, __WEBPACK_EXTERNAL_MODULE__191__, __WEBPACK_EXTERNAL_MODULE__297__) {
return 