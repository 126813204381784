import {InputAdornment, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React, {ChangeEvent, useContext, useState} from 'react';
import {ElicitationContext} from 'src/ElicitationContext/ElicitationContext';
import ICriterion from 'src/Interface/ICriterion';
import {getUpperBound} from 'src/ThresholdElicitation/ThresholdElicitationUtil';
import significantDigits, {canBePercentage, getUnitLabel} from 'src/Util/util';
import {ThresholdElicitationContext} from '../../../ThresholdElicitationContext';

export default function ThresholdElicitationValueInput({
  criterion
}: {
  criterion: ICriterion;
}): JSX.Element {
  const {showPercentages, stepSizeByCriterion} = useContext(ElicitationContext);

  const {setCriterionInputValue, getInputValueForCriterion, setIsValidValue} =
    useContext(ThresholdElicitationContext);

  const [initialValue] = useState(getInputValueForCriterion(criterion.id));
  const [value, setValue] = useState<number>(initialValue);
  const [inputError, setInputError] = useState<string>(
    initialValue === 0 ? 'Value cannot be 0' : ''
  );

  const unit = criterion.dataSources[0].unitOfMeasurement;
  const usePercentage = showPercentages && canBePercentage(unit.type);
  const modifier = usePercentage ? 100 : 1;
  const minValue = unit.lowerBound === null ? -Infinity : unit.lowerBound;
  const maxValue =
    unit.upperBound === null ? Infinity : getUpperBound(usePercentage, unit);
  const stepSize = significantDigits(
    stepSizeByCriterion[criterion.id] * modifier
  );

  function handleValueChanged(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const newValue = Number.parseFloat(event.target.value);

    if (event.target.value === '') {
      handleInvalidValue('Please enter a numeric value', newValue);
    } else if (
      _.isNaN(newValue) ||
      newValue === undefined ||
      newValue === null
    ) {
      setInputError('Only numerical values accepted');
    } else if (newValue < minValue || newValue > maxValue) {
      handleValueOutOfBounds(
        `Value needs to be between ${minValue} and ${maxValue}`,
        newValue
      );
    } else if (newValue === 0) {
      handleInvalidValue('Value cannot be 0', newValue);
    } else {
      handleValidValue(newValue);
    }
  }

  function handleInvalidValue(errorMessage: string, value: number): void {
    setInputError(errorMessage);
    setIsValidValue(criterion.id, false);
    setValue(value);
  }

  function handleValueOutOfBounds(errorMessage: string, value: number): void {
    const normalizedValue = usePercentage
      ? significantDigits(value / 100)
      : value;
    setInputError(errorMessage);
    setIsValidValue(criterion.id, false);
    setValue(normalizedValue);
  }

  function handleValidValue(value: number): void {
    const normalizedValue = usePercentage
      ? significantDigits(value / 100)
      : value;
    setInputError('');
    setCriterionInputValue(criterion.id, normalizedValue);
    setIsValidValue(criterion.id, true);
    setValue(normalizedValue);
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <TextField
          id={`input-${criterion.id}`}
          value={_.isNaN(value) ? '' : significantDigits(value * modifier)}
          onChange={handleValueChanged}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getUnitLabel(unit, showPercentages)}
              </InputAdornment>
            )
          }}
          inputProps={{
            min: minValue,
            max: maxValue,
            step: stepSize
          }}
          error={!!inputError}
          helperText={inputError ? inputError : ''}
        />
      </Grid>
    </Grid>
  );
}
