import _ from 'lodash';
import IUnitOfMeasurement from 'src/Interface/IUnitOfMeasurement';
import significantDigits from 'src/Util/util';

export function increaseSliderRange(
  currentUpperValue: number,
  stepSize: number,
  unit: IUnitOfMeasurement
): number {
  const theoreticalUpper = unit.type === 'custom' ? unit.upperBound : 1;
  const limit = _.isNull(theoreticalUpper) ? Infinity : theoreticalUpper;
  const newTo = significantDigits(currentUpperValue + stepSize * 10);
  return Math.min(newTo, limit);
}

export function isSliderExtenderDisabled(
  currentUpperValue: number,
  unit: IUnitOfMeasurement
): boolean {
  const theoreticalUpper = unit.type === 'custom' ? unit.upperBound : 1;
  return currentUpperValue === theoreticalUpper;
}
