import {IconButton, Mark, Slider, Tooltip} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React, {useContext} from 'react';
import {ElicitationContext} from 'src/ElicitationContext/ElicitationContext';
import {
  canBePercentage,
  getPercentifiedValueLabel,
  getUnitLabel
} from 'src/Util/util';
import {ThresholdElicitationContext} from '../../ThresholdElicitationContext';
import {
  increaseSliderRange,
  isSliderExtenderDisabled
} from './ReferenceSliderUtil';

export default function ReferenceSlider(): JSX.Element {
  const {getCriterion, showPercentages} = useContext(ElicitationContext);
  const {
    direction,
    equivalentChangeForReferenceCriterion,
    mostImportantCriterionId,
    sliderParameters,
    setEquivalentChangeForReferenceCriterion,
    setSliderUpperBound
  } = useContext(ThresholdElicitationContext);
  const referenceCriterion = getCriterion(mostImportantCriterionId);

  function handleSliderChanged(
    event: React.ChangeEvent<any>,
    newValue: number
  ): void {
    setEquivalentChangeForReferenceCriterion(newValue);
  }

  const unit = referenceCriterion.dataSources[0].unitOfMeasurement;
  const usePercentage = showPercentages && canBePercentage(unit.type);
  const unitLabel: string = getUnitLabel(unit, showPercentages);
  const sliderMarks: Mark[] = [
    {
      value: sliderParameters.minValue,
      label: getPercentifiedValueLabel(sliderParameters.minValue, usePercentage)
    },
    {
      value: sliderParameters.maxValue,
      label: getPercentifiedValueLabel(sliderParameters.maxValue, usePercentage)
    }
  ];

  function formatSliderLabel(sliderValue: number): string {
    return `${getPercentifiedValueLabel(sliderValue, usePercentage)}`;
  }

  function increaseUpperBound(): void {
    const newUpperBound = increaseSliderRange(
      sliderParameters.maxValue,
      sliderParameters.step,
      referenceCriterion.dataSources[0].unitOfMeasurement
    );
    setSliderUpperBound(newUpperBound);
  }

  const isExtensionDisabled = isSliderExtenderDisabled(
    sliderParameters.maxValue,
    referenceCriterion.dataSources[0].unitOfMeasurement
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          {`By how much ${unitLabel} should ${referenceCriterion.title} ${
            direction === 'worsening' ? 'worsen' : 'improve'
          }?`}
        </Typography>
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Slider
          id="reference-criterion-slider"
          valueLabelDisplay="on"
          valueLabelFormat={formatSliderLabel}
          value={equivalentChangeForReferenceCriterion}
          min={sliderParameters.minValue}
          max={sliderParameters.maxValue}
          onChange={handleSliderChanged}
          step={sliderParameters.step}
          style={{maxWidth: '500px', marginRight: '1em', marginTop: '2em'}}
          marks={sliderMarks}
        />
        <Tooltip
          title={
            isExtensionDisabled
              ? 'Cannot extend beyond theoretical range'
              : 'Extend the range'
          }
        >
          <span>
            <IconButton
              id={'extend-upper-bound'}
              onClick={increaseUpperBound}
              disabled={isExtensionDisabled}
            >
              <ChevronRight
                color={isExtensionDisabled ? 'disabled' : 'primary'}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </>
  );
}
